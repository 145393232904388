import {Serializer} from '@matchsource/api-utils';
import {glstringSerializer} from '@matchsource/api/hla-shared';
import {HlaHistoryLocusDTO} from './hla-history.interfaces';
import {formatType} from '@matchsource/api/hla';
import {HlaHistoryLocusModel} from '@matchsource/models/hla';

export const hlaHistoryLocusSerializer: Serializer<HlaHistoryLocusModel, HlaHistoryLocusDTO> = {
  fromDTO(input: HlaHistoryLocusDTO): HlaHistoryLocusModel {
    return {
      name: '',
      type1: input.antigen1,
      type2: input.antigen2,
      typingMethod1: input.objectType1,
      typingMethod2: input.objectType2,
      type1Formatted: formatType({
        typingMethod: input.objectType1,
        value: input.antigen1,
        hlaExprChar: input.hlaExprChar1,
      }),
      type2Formatted: formatType({
        typingMethod: input.objectType2,
        value: input.antigen2,
        hlaExprChar: input.hlaExprChar2,
      }),
      glstring: input.glstring,
      glstringBox: glstringSerializer.fromDTO(input.glstringBox),
    };
  },
};
