import {Serializer} from '@matchsource/api-utils';
import {HlaHistoryLocusDTO} from './hla-history.interfaces';
import {hlaHistoryLocusSerializer} from './hla-history-locus.serializer';
import {PreferredTestResultsModel} from '@matchsource/models/hla';

export const typingResultsSerializer: Serializer<PreferredTestResultsModel, MsApp.Dictionary<HlaHistoryLocusDTO>> = {
  fromDTO(input: MsApp.Dictionary<HlaHistoryLocusDTO>, index?: number): PreferredTestResultsModel {
    input = input || {};

    return Object.keys(input).reduce(
      (hla, locus) => {
        const name = locus.replace(/^HLA-/, '');
        const item = hlaHistoryLocusSerializer.fromDTO(input[locus]);
        item.name = name;

        hla[name] = item;

        return hla;
      },
      {index} as PreferredTestResultsModel
    );
  },
};
